import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["collection"];
  static values = {
    domain: String,
    token: String,
    collectionId: String
  }

  connect() {
    this.initializeShopifyBuyButton();
  }

  initializeShopifyBuyButton() {
    var scriptURL = 'https://sdks.shopifycdn.com/buy-button/latest/buy-button-storefront.min.js';

    if (window.ShopifyBuy) {
      if (window.ShopifyBuy.UI) {
        this.ShopifyBuyInit();
      } else {
        this.loadScript(scriptURL);
      }
    } else {
      this.loadScript(scriptURL);
    }
  }

  loadScript(scriptURL) {
    var script = document.createElement('script');
    script.async = true;
    script.src = scriptURL;
    (document.getElementsByTagName('head')[0] || document.getElementsByTagName('body')[0]).appendChild(script);
    script.onload = this.ShopifyBuyInit.bind(this);
  }

  ShopifyBuyInit() {
    var client = ShopifyBuy.buildClient({
      domain: this.domainValue,
      storefrontAccessToken: this.tokenValue,
    });
    ShopifyBuy.UI.onReady(client).then((ui) => {
      ui.createComponent('collection', {
        id: this.collectionIdValue,
        node: this.collectionTarget,
        moneyFormat: '%24%7B%7Bamount%7D%7D',
        options: {
          "product": {
            "styles": {
              "product": {
                "@media (min-width: 601px)": {
                  "max-width": "calc(25% - 20px)",
                  "margin-left": "20px",
                  "margin-bottom": "50px",
                  "width": "calc(25% - 20px)"
                },
                "img": {
                  "height": "calc(100% - 15px)",
                  "position": "absolute",
                  "left": "0",
                  "right": "0",
                  "top": "0"
                },
                "imgWrapper": {
                  "padding-top": "calc(75% + 15px)",
                  "position": "relative",
                  "height": "0"
                }
              },
              "button": {
                "font-family": "Droid Sans, sans-serif",
                ":hover": {
                  "background-color": "#3d3d3d"
                },
                "background-color": "#242424",
                ":focus": {
                  "background-color": "#3d3d3d"
                },
                "border-radius": "10px"
              }
            },
            "text": {
              "button": "Add to cart"
            },
            "googleFonts": [
              "Droid Sans"
            ]
          },
          "productSet": {
            "styles": {
              "products": {
                "@media (min-width: 601px)": {
                  "margin-left": "-20px"
                }
              }
            }
          },
          "modalProduct": {
            "contents": {
              "img": false,
              "imgWithCarousel": true,
              "button": false,
              "buttonWithQuantity": true
            },
            "styles": {
              "product": {
                "@media (min-width: 601px)": {
                  "max-width": "100%",
                  "margin-left": "0px",
                  "margin-bottom": "0px"
                }
              },
              "button": {
                "font-family": "Droid Sans, sans-serif",
                ":hover": {
                  "background-color": "#3d3d3d"
                },
                "background-color": "#242424",
                ":focus": {
                  "background-color": "#3d3d3d"
                },
                "border-radius": "10px"
              }
            },
            "googleFonts": [
              "Droid Sans"
            ],
            "text": {
              "button": "Add to cart"
            }
          },
          "option": {},
          "cart": {
            "styles": {
              "button": {
                "font-family": "Droid Sans, sans-serif",
                ":hover": {
                  "background-color": "#3d3d3d"
                },
                "background-color": "#242424",
                ":focus": {
                  "background-color": "#3d3d3d"
                },
                "border-radius": "10px"
              }
            },
            "text": {
              "total": "Subtotal",
              "button": "Checkout"
            },
            "googleFonts": [
              "Droid Sans"
            ]
          },
          "toggle": {
            "styles": {
              "toggle": {
                "font-family": "Droid Sans, sans-serif",
                "background-color": "#242424",
                ":hover": {
                  "background-color": "#3d3d3d"
                },
                ":focus": {
                  "background-color": "#3d3d3d"
                }
              }
            },
            "googleFonts": [
              "Droid Sans"
            ]
          }
        }
      });
    });
  }
}
