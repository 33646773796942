import { Controller } from "@hotwired/stimulus"
import YouTubePlayerPlus from 'youtube-player-plus';

// Connects to data-controller="youtube-player"
export default class extends Controller {
  static values = { youtubeEmbedId: String, youtubeUrl: String }
  static targets = ["youtubePlayerContainer"];
  player = null; // Initialize the player variable

  connect() {
    this.initYouTubePlayer();
  }

  initYouTubePlayer() {
    const options = {}; // Add any configuration options here
    this.player = new YouTubePlayerPlus(this.youtubePlayerContainerTarget, options);
    const autoplay = false; // Set to true if you want the video to play automatically
    const start = 0; // Start time in seconds

    this.player.load(this.youtubeEmbedIdValue, autoplay, start);
  }

  copyTimestamp() {
    // Check if the player has been initialized
    if (this.player) {
      // Copy the formatted timestamp to the clipboard
      navigator.clipboard.writeText(this.getCurrentTimestamp())
    }
  }

  // Add a click event listener to the button
  insertTimestamp(e) {
    e.preventDefault();
    // Get the current timestamp
    const noteTextarea = document.getElementById("note-textarea");

    if (noteTextarea) {
      const currentTimeInSeconds = this.player.getCurrentTime();
      const timestamp = this.getCurrentTimestamp(currentTimeInSeconds);
      const timestampLink = `[${timestamp}](${this.getYoutubeVideoUrlWithTime(currentTimeInSeconds)})`
      noteTextarea.value += timestampLink;
    }
  }

  getCurrentTimestamp(currentTimeInSeconds) {

    // Convert the timestamp to a human-readable format (e.g., minutes:seconds)
    const minutes = Math.floor(currentTimeInSeconds / 60);
    const seconds = Math.floor(currentTimeInSeconds % 60).toLocaleString('en-US', {
      minimumIntegerDigits: 2,
      useGrouping: false
    });
    const formattedTimestamp = `${minutes}:${seconds}`;

    return formattedTimestamp;
  }

  getYoutubeVideoUrlWithTime(currentTimeInSeconds) {
    // Replace 'YOUR_VIDEO_ID' with the actual YouTube video ID
    const videoId = this.youtubeEmbedIdValue;
    
    // Create the YouTube video URL with the 't' parameter for the current time
    return `https://www.youtube.com/watch?v=${videoId}&t=${currentTimeInSeconds}s`;
  }
}
